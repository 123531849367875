<template>
	<div>
		<v-card>
			<v-container>
				<v-row>
					<h3 class="mt-3 ml-6">MOVIMIENTOS DE PARIHUELA</h3>
					<v-spacer> </v-spacer>
					<v-btn
						@click="$emit('close')"
						class="mt-3 mr-3"
						small
						fab
						color="error"
						><i style="font-size: 16px" class="fas fa-times"></i
					></v-btn>
				</v-row>

				<v-row>
					<v-col class="pb-0">
						<v-divider></v-divider>
					</v-col>
				</v-row>

				<v-row>
<!-- 					<v-col cols="3" class="pt-0 mt-4" >
						<b>1 Ingreso: {{EntryInitial}}</b>
					</v-col> -->
					<v-col cols="3" class="pt-0 mt-4" >
						<b>Categoria: {{CategoryName}}</b>
					</v-col>
					<v-col cols="3" class="pt-0 mt-4" >
						<b>Peso: {{Weight}}</b>
					</v-col>
				</v-row>

				<v-row>
					
					<v-col cols="12" class="pt-0">
						<!-- <v-list> -->
							<!-- <v-list-item> -->
								<v-data-table
                                    dense
                                    :headers="headers"
                                    :items="itemMovimients"
                                    :items-per-page="-1"
                                    hide-default-footer
									disable-sort
                                >

                                </v-data-table>
							<!-- </v-list-item> -->

						<!-- </v-list> -->
					</v-col>
					<v-col cols="12" class="pt-0">
						<CardLineChart :text="'N° de dias'" :objLineX="objLineX" :objLineY="objLineY"></CardLineChart>
					</v-col>

				</v-row>
			</v-container>
		</v-card>
	</div>
</template>

<script>
	import CardLineChart from '@/components/Utils/Cards/CardLineChart' ;
	export default {
		props: {
            itemMovimients: null
		},
		components: { CardLineChart},
		data() {
			return {
                headers: [

                    {text: "Descripción", value: "FtmDescription", width: 130},
                    {text: "Tipo Proceso", value: "MtsDescription", width: 100},
                    {text: "Inicio", value: "MtpDateBegin", width: 120},
                    {text: "Fin", value: "MtpDateEnd", width: 120},
					{text: "Dias", value: "MtpDayNew", width: 100, align: "center"},
                    {text: "HH:MM", value: "MtpMinuteNew", width: 100, align: "center"},
                    {text: "Tipo Cultivo", value: "TypeCropName", width: 100},
                    {text: "Cultivo", value: "TypeCultiveName", width: 100},
                    {text: "Variedad", value: "VrtName", width: 100},
                ],

				EntryInitial: "",
				CategoryName: "",
				Weight: 0,

				objLineX: [],
				objLineY: []

			};
		},

		created() {
            console.log("itemMovimients",this.itemMovimients);

			if(this.itemMovimients.length > 0) {
				this.EntryInitial = this.itemMovimients[0].LlpDateEntryTunnelInitial
				this.CategoryName = this.itemMovimients[0].TypeFrozenCategoryName
				this.Weight = this.itemMovimients[0].LlpWeight


				this.itemMovimients.forEach(element => {
					if(element.FbcDateEnd !== null){
						element.FbcSecondsNew = 
								this.$fun.isDifferenceMinutes(
									element.FbcDateBegin,
									element.FbcDateEnd,
									"seconds"
								) * -1;

						element.FbcMinuteNew = this.$fun.secondsToString(element.FbcSecondsNew)
						
					}

					this.objLineX.push(element.TypeChamberName)
					this.objLineY.push(element.FbcDayNew)
					
					
				});

				console.log("lineaaaaaaa x",this.objLineX, this.objLineY);

				
			}
		},

		methods: {


		},
	};
</script>

<style>
</style>