
<template>
	<div>
					<v-card outlined>
						<v-row style="margin: auto;" justify="center">
							<v-col
								align="center"
								cols="8"
								lg="4"
								md="4"
								v-for="item in itemsChamber"
								:key="item.TypeChamber"
							>
								<v-card 
									class="mx-auto"
									outlined
									color="#E2FFFD"
									
								>
									<v-row style="z-index:2;transform: rotateZ(0deg);margin-top: -2%;margin-left: -10%;position: absolute;width: 133px;border-radius: 20px;" v-if="item.inParihuelaUsage > 0" >
										<v-col cols="12" class="mt-0">
											<v-btn  v-if="item.inParihuelaUsage > 0" small color="error"  >Max. Dias Parih.: {{item.CchDay}}</v-btn>
										</v-col>
										<v-col cols="12" lg="12" style="margin-top: -20px">
											<v-btn  v-if="item.CchTemperatureBegin > 0" small color="error"  >Temp: {{item.CchTemperatureBegin}}</v-btn>
										</v-col>
										<v-col cols="12" lg="12" style="margin-top: -20px">
											<v-btn  v-if="item.CchEthyleneHour > 0" small color="error"  >H. Etile: {{item.CchEthyleneHour}}</v-btn>
										</v-col>
									</v-row>
									<v-img
										lazy-src="https://img.interempresas.net/fotos/2852264.jpeg"
										
										src="https://img.interempresas.net/fotos/2852264.jpeg"
										><v-btn
											style=" margin: auto; display: flex; justify-content: space-evenly; "
											color="success"
											fab
											@click="openModal(item)"
											class="mt-8"
											width="100"
											height="100"
										>
											<span class="white--text text-h4"><b>
												<h1 style="font-size: 50px; ">
													{{item.PalletInChamber}}
												</h1>
											</b></span>
										</v-btn>
									</v-img>
									<v-card color="#E2FFFD" outlined width="200">
										<h2>{{ item.TypeChamberName }} <br>
											<v-chip 
												color="info"
											>
												{{item.PalletAvailableUnit}}
											</v-chip> 
										</h2>
									</v-card>
								</v-card>
								
							</v-col>
						</v-row>
					</v-card>
				<!-- </div>
			</v-row>
		</v-container> -->

		<v-dialog
			v-model="dialog"
			v-if="dialog"
			transition="dialog-bottom-transition"
			persistent
			fullscreen
		>
			<pallet-in-chamber-detail
				@closeDetail = "closeDetail"
				:dataChamber="dataChamber"
				:dataPrihuela="dataPrihuela"
			></pallet-in-chamber-detail>
		</v-dialog>

		<v-dialog
			v-model="dialogExitChamber"
			v-if="dialogExitChamber"
			width="500"
			persistent
		>
			<v-card>
				<v-container>
					<v-row>
						<s-toolbar
							color="#BDBDBD"
							label="Parámetros"
							save
							@save="saveProcessGroup()"
							close
							@close="closeDialogExitTunnel()"
							dark
						>

						</s-toolbar>
					</v-row>
					<v-row>
						<v-col>
							<s-text
								decimal
								v-model="processGroup.MtpTemperatureBegin"
								label="Temperatura"
							>

							</s-text>
						</v-col>
						<v-col>
							<s-text
								decimal
								v-model="processGroup.MtpEthyleneHour"
								label="Horas etileno"
							>

							</s-text>
						</v-col>
					</v-row>
					
				</v-container>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import _sMaturationTunnelPalletService from '../../../services/FrozenProduction/MaturationTunnelPalletService';
    import sTunnelMaduration from "../../../services/FrozenProduction/TunnelMadurationService";
    import PalletInChamberDetail from './PalletInChamberDetail.vue'; 
	import signalr from "signalr";
	import _sBoardginChamberService from '@/services/FrozenProduction/BoardingChamberService.js';
	

	export default {
		components: { 
			 PalletInChamberDetail
		},

		data() {
			return {
				itemsChamber: [],
				itemsStateTunnel: [],
				dialog:false,
				dataChamber: {},
                dataPrihuela: [],
				dialogExitChamber: false,
				processGroup: {},
			}
		},

		methods: {

			initialize(){

				_sMaturationTunnelPalletService.listStateMaturation(this.$fun.getUserID()).then(r => {
					if(r.status == 200){
						this.itemsStateTunnel = r.data
					}
				})


				_sBoardginChamberService
				.listchamber(this.$fun.getUserID())
				.then(r => {
					if(r.status == 200){

						this.itemsChamber = r.data;
						console.log("al levantar",this.itemsChamber);

					}
					
				});
			},


			openModal(item)
			{
				this.dataChamber = item;
                console.log("🚀 ~ file: PalletInChamber.vue ~ line 200 ~ this.dataChamber", this.dataChamber)
				
				let filter = {
					TypeChamber: item.TypeChamber,
				}

				_sBoardginChamberService
				.list(filter, this.$fun.getUserID()).then(resp => {
					if(resp.status == 200){
						
						resp.data.forEach(element => {
							element.FbcDateBegin = this.$moment(element.FbcDateBegin).format(
								this.$const.FormatDateTimeDB
							);
							element.FbcSecondsNew = 
								this.$fun.isDifferenceMinutes(
									element.FbcDateNewBegin,
									element.FbcDateNewEnd,
									"seconds"
								) * -1;

							element.FbcMinuteNew = this.$fun.secondsToString(element.FbcSecondsNew)
						});

						this.dataPrihuela = resp.data;
                        
						this.dialog = true;
					}
				})

				
			},

			 closeDetail()
			{
				this.dialog = false;
				this.initialize()
			},

			openDialogExitTunnel(item)
			{
				this.processGroup.xFlag = 2;
				this.processGroup.TcaID = item.CchID;
				this.processGroup.UsrUpdateID = this.$fun.getUserID();
				this.dialogExitChamber = true;
			},

			saveProcessGroup()
			{
				console.log("Guardar temperatura", this.processGroup);

				
				this.$fun.alert("¿Seguro de guardar?", "question")
				.then(r => {
					if(r.value){
						_sMaturationTunnelPalletService
						.beginprocessgroup(this.processGroup ,this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200){
								this.$fun.alert("Guardado Correctamente", "success");
								this.closeDialogExitTunnel();
							}
						})
					}
				})
			},

			closeDialogExitTunnel()
			{
				this.dialogExitChamber = false;
				this.initialize()
			},
			
		},

		created () {
			this.initialize();
		},

		mounted() {
			let recaptchaScript = document.createElement("script");
			recaptchaScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
			document.head.appendChild(recaptchaScript);

			recaptchaScript.addEventListener("load", () => {
				$.connection.hub.url = this.$const.URL + "/signalr";
				var chat = $.connection.notification;
				chat.client.FrozenEntryParihuelaIntunnel = group => {
					this.initialize();
				};

				$.connection.hub.start({ jsonp: true }).done(e => {
					chat.invoke("addToGroup", "frozenUpdateWaitingEntryParihuelaIntunnel");
				});
			});
		},
	}
</script>