<template>
	<v-card>
		<s-toolbar
			dark
			color="#BDBDBD"
			:label="dataChamber.TypeChamberName"
			close
			@close="closeDetail"
		></s-toolbar>
		<v-row>
			<div class="col-md-12 col-sm-12">
				<v-card outlined>
					<v-row style="margin: auto;" justify="center">
						<v-col
							align="center"
							cols="12"
						>
							<h2><b>{{dataChamber.TypeChamberName }}</b></h2>
						</v-col>
					</v-row>
				</v-card>

				<v-card dense>
					<v-row style="margin: auto;">

						<v-alert
							v-model="alert"
							border="right"
							color="#E2FFFD"
							dark
							dense
							shaped
							style="color: black; border-radius: 10px 10px 10px 10px; z-index: 5; width: 100%; right: 0px; top: 5%; margin-top: ;}"
						>
						<!-- #9EB8F6 -->
							<div>
								<v-row justify="center">
									<v-col cols="6" lg="4" md="4" >
										<b>Capacidad Total: {{dataChamber.TypeChamberCapacity}}</b>
										
									</v-col>
									<v-col cols="6" lg="4" md="4">
										<b>Capacidad Usado: {{dataChamber.PalletInChamber }}</b>
										
									</v-col>
									<v-col cols="12" lg="4" md="4" align="center">
										<b>Capacidad Disponible: {{ dataChamber.PalletAvailableUnit}}</b>
										
									</v-col>
								</v-row>
							</div>
						</v-alert>

					</v-row>
				</v-card>

				<v-card dense>
					<v-row style="margin: auto;">

						<v-col cols="12">
							<v-card flat>
								<v-row>
									<v-col align="center" cols="12" lg="12" md="12">
										<drag-drop
											:dropzones="itemsPositionAll"
											:originalData="dataPrihuela"
											:dropzonesTitle="'PALLETS EN CÁMARA'"
											:inPlace="true"
											:hidden="false"
										
										>
											<template #dd-card="{ cardData }">
												<frz-custom-card-parihuela-chamber
													:data="cardData"
													@viewMovimients="viewMovimients($event)"
												/>
											</template>
										</drag-drop>
									</v-col>
								</v-row>
							</v-card>
						</v-col>
					</v-row>
				</v-card>
			</div>
		</v-row>

			<v-dialog persistent v-model="openModalMovimients" v-if="openModalMovimients" width="1200">
				<frz-pallet-movimients :itemMovimients="itemMovimients" @close="openModalMovimients=false"></frz-pallet-movimients>
			</v-dialog>

	</v-card>

	
</template>

<script>
	import _sMaturationTunnelPalletService from '@/services/FrozenProduction/MaturationTunnelPalletService';
	import FrzCustomCardParihuelaChamber from "./FrzCustomCardParihuelaChamber.vue";
	import FrzPalletMovimients from "./FrzPalletMovimients.vue";
	import SselectClientSap from '../../../components/Sale/SselectClientSap.vue';
	import _sPalletProcessService from "@/services/FrozenProduction/PalletProcessService.js";
	import _sBoardginChamberService from '@/services/FrozenProduction/BoardingChamberService.js';

	export default {
		props: {
			dataChamber: null,
			dataPrihuela: null
		},

		components: {FrzCustomCardParihuelaChamber, FrzPalletMovimients, SselectClientSap},

		data() {
			return {
				palletProcess: {},
				itemsPositionAll: [],
				alert: true,
				itemMovimients: [],
				openModalMovimients:false
			}
		},

		methods: {
			inputWeightGross(weight)
			{
				this.palletProcess.PprWeightNet = this.palletProcess.PprWeightGross - this.palletProcess.PprWeightJaba*this.palletProcess.PprQuantityJaba
			},

			getDataInitial(data)
			{
				data.forEach(element => {
					if(element.DedValue == 1){
						this.palletProcess.PprWeightJaba = element.DedHelper;
					}
				})
			},

			generatedPositions(){
               for (var i = 0; i < this.dataChamber.TypeChamberCapacity; i++) {
                    var objrrr = {id: (i+1), name: "Posición 0" + (i+1), children: []}
                    this.itemsPositionAll.push(objrrr)
                }


					this.itemsPositionAll.forEach(element => {
                        
                        element.children = []
                        var obkkk = this.dataPrihuela.find((x) => x.FbcPosition == element.id)

                        if(obkkk !== undefined){
                            element.children.push(obkkk)

                           /*  this.dataPrihuela = this.dataPrihuela.filter(
                                x => x.ExpID != obkkk.ExpID,
                            ); */
                        }
                        
                    });
            },

			viewMovimients(item){

				_sBoardginChamberService
				.listmovimients({ExpID: item.ExpID}, this.$fun.getUserID()).then(resp => {
					if(resp.status == 200){

						resp.data.forEach(element => {
							element.FbcDateBegin = this.$moment(element.FbcDateBegin).format(
								this.$const.FormatDateTimeDB
							);

							if(element.FbcDateEnd !== null){
								element.FbcDateEnd = this.$moment(element.FbcDateEnd).format(
									this.$const.FormatDateTimeDB
								);
							}
						});

						this.itemMovimients = resp.data
						console.log("movimient", this.itemMovimients);

						this.openModalMovimients  =true
					}
				})


				console.log("viewMovimients",item);
			},


			
			closeDetail() {
				this.$emit('closeDetail');
			}
		},

		created () {
			console.log("data camra ", this.dataChamber);
			console.log("al cargar",this.dataPrihuela);
            this.generatedPositions();

			
		},
	}
</script>

